import { useEffect, useState } from 'react';
import Editor from './components/Editor';
import { loadFromBytebin, loadFromURL } from './util/storage';

const INITIAL = Symbol();
const LOADING = Symbol();
const LOADED = Symbol();

type LoadingState = typeof INITIAL | typeof LOADING | typeof LOADED;

export default function App() {
  const [pasteId] = useState<string | undefined>(getPasteIdFromUrl);
  const [contentURL] = useState<string | undefined>(getContentURLFromUrl);
  const [urlContentType] = useState<string | undefined>(getContentTypeFromUrl);
  const [state, setState] = useState<LoadingState>(INITIAL);
  const [forcedContent, setForcedContent] = useState<string>('');
  const [actualContent, setActualContent] = useState<string>('');
  const [contentType, setContentType] = useState<string>();

  function setContent(content: string) {
    setActualContent(content);
    setForcedContent(content);
  }

  useEffect(() => {
    if (contentURL && state === INITIAL) {
      setState(LOADING);
      setContent('Loading...');

      loadFromURL(contentURL).then(({ ok, content, type }) => {
        if (ok) {
          setContent(content);
          if (type) {
            setContentType(urlContentType);
          }
        } else {
          setContent(get404Message("unknown"));
        }
        setState(LOADED);
      });
    }
  }, [pasteId, state]);

  return (
    <Editor
      forcedContent={forcedContent}
      actualContent={actualContent}
      setActualContent={setActualContent}
      contentType={contentType}
      pasteId={pasteId}
    />
  );
}

function get404Message(pasteId: string) {
  return `
  ██╗  ██╗ ██████╗ ██╗  ██╗
  ██║  ██║██╔═████╗██║  ██║
  ███████║██║██╔██║███████║
  ╚════██║████╔╝██║╚════██║
       ██║╚██████╔╝     ██║
       ╚═╝ ╚═════╝      ╚═╝

  not support !!
`;
}

function getPasteIdFromUrl() {
  const path = window.location.pathname;
  if (path && /^\/[a-zA-Z0-9]+$/.test(path)) {
    return path.substring(1);
  } else {
    return undefined;
  }
}

function getContentURLFromUrl(): string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('content') as string | undefined;
}

function getContentTypeFromUrl(): string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('content_type') as string | undefined;
}
